import { datadogRum } from '@datadog/browser-rum';
import { createVfm } from 'vue-final-modal';
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import PiniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import ClickOutsideVue3 from 'click-outside-vue3';

// import VueDraggableGrid from '@noction/vue-draggable-grid';
import VueSignaturePad from 'vue-signature-pad';
import Vueform from '@vueform/vueform/plugin';
import VueTelInput from 'vue-tel-input';
import Toast from 'vue-toastification';
import PrettySize from 'prettysize';
import VueTippy from 'vue-tippy';
import Mitt from 'mitt';

import * as Sentry from '@sentry/vue';

import { version } from '../package.json';
import VueformConfig from '../vueform.config';

import { FixedPosition } from '~/common/utils/fixed-position-directive';
import { truncate, truncateMiddle } from '~/common/filters/truncate.js';
import { load_js_css_file } from '~/common/utils/load-script.util';
import { $toast, track_event } from '~/common/utils/common.utils';
import { $date, $date_relative } from '~/common/utils/date.util';
import { useCommonStore } from '~/common/stores/common.store';
import { useI18nStore } from '~/common/stores/i18n.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { services } from '~/common/installers/services';
import router from '~/common/router/';
import App from '~/App.vue';

// import '@noction/vue-draggable-grid/styles';
import 'vue-toastification/dist/index.css';
import 'vue-tel-input/vue-tel-input.css';
import 'vue-final-modal/style.css';
import 'tippy.js/dist/tippy.css';
import '~/common/styles/main.css';

const commit_ref = import.meta.env?.VITE_COMMIT_REF;
const commit_ref_str = commit_ref ? ` [${commit_ref.substring(0, 9)}]` : '';

logger.forceLog(`%cVersion: ${version}${commit_ref_str}`, [
  'background-color: dodgerBlue',
  'border-radius: 2px',
  'padding: 2px 6px',
  'color: white',
].join(';'));

const emitter = Mitt();
const app = createApp(App);
app.use(router);
app.directive('fixed-position', FixedPosition);
const vue_final_modal = createVfm();
app.use(vue_final_modal);
app.use(VueSignaturePad);
app.use(ClickOutsideVue3);

// app.use(VueDraggableGrid);
app.use(
  VueTippy,
  {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'auto',
      allowHTML: false,
    },
  },
);

app.use(Vueform, VueformConfig);
app.use(VueTelInput);

const pinia = createPinia();
pinia.use(({ store }) => {
  store.$services = markRaw(services);
  store.$router = markRaw(router);
  store.$toast = markRaw($toast);
  store.$track_event = markRaw(track_event);
  store.$date = markRaw($date);
  store.$date_relative = markRaw($date_relative);
});
pinia.use(PiniaPluginPersistedstate);
app.use(pinia);

const default_toast_options = {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  icon: false,
  containerClassName: 'hawk-toast-container',
  closeButton: false,
};
app.use(Toast, default_toast_options);

// install all modules under `installers/`
Object.values(import.meta.glob('./**/installers/*.js')).forEach(i => i.install?.({ app, router }));

const common_store = useCommonStore();
const i18n_store = useI18nStore();
const auth_store = useAuthStore();

window.onerror = function (message) {
  if (message.includes('import') && message.includes('module')) {
    common_store.reload_on_next_route_change = true;
    logger.info('[DEBUG] main.js::107\nModule import failed. Reload will be necessary.');
  }
};

if (window.location.hostname !== 'localhost') {
  const ddrum_config = {
    applicationId: '93a3f294-4c3c-4a65-a057-311cbb741340',
    clientToken: 'pub4b79f28be5b247503c3756a83e442e5f',
    site: 'datadoghq.com',
    service: 'taskmapper',
    env: common_store.is_production ? 'production' : 'staging',
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingOrigins: [
      'https://api.sensehawk.com',
      'https://api-dev.sensehawk.com',
      'https://api-qa.sensehawk.com',
      /https:\/\/.*\.sensehawk\.com/,
    ],
  };
  datadogRum.init(ddrum_config);
  datadogRum.startSessionReplayRecording();
  auth_store.is_datadog_initialized = true;

  Sentry.init({
    app,
    dsn: 'https://af124279ddb56476b9810ff3367a96e9@o266422.ingest.us.sentry.io/4507378075631616',
  });
}

load_js_css_file(
  'https://cdn.onesignal.com/sdks/OneSignalSDK.js',
  'onesignal',
  'js',
).then(() => {
  window.OneSignal = window.OneSignal || [];
  window.OneSignal.push(() => {
    if (window.location.hostname === 'app.taskmapper.com' || window.location.hostname === 'dev.taskmapper.com') {
      window.OneSignal.SERVICE_WORKER_PARAM = { scope: '/' };
      window.OneSignal.SERVICE_WORKER_PATH = 'OneSignalSDKWorker.js';
      window.OneSignal.SERVICE_WORKER_UPDATER_PATH = 'OneSignalSDKUpdaterWorker.js';
      window.OneSignal.init({
        appId: import.meta.env.VITE_APP_ONESIGNAL_ID,
        autoResubscribe: true,
        autoRegister: true,
      });
      auth_store.is_one_signal_initialized = true;
    }
  });
});

// set up global variables
app.config.globalProperties.$t = i18n_store.$t;
app.config.globalProperties.$date = $date;
app.config.globalProperties.$date_relative = $date_relative;
app.config.globalProperties.$prettysize = PrettySize;
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.logged_in_user_details = auth_store.logged_in_user_details;
app.config.globalProperties.active_asset = common_store.active_asset;

// app-level provides
app.provide('$date', $date);
app.provide('$date_relative', $date_relative);
app.provide('$track_event', track_event);
app.provide('$toast', $toast);
app.provide('$services', services);
app.provide('$t', markRaw(i18n_store.$t));
app.provide('current_organization', auth_store.current_organization);
app.provide('logged_in_user_id', auth_store.logged_in_user_id);

// global filters
app.config.globalProperties.$filters = {
  truncate, truncateMiddle,
};

// Mount application
Promise.allSettled([
  i18n_store.load_translations(),
  auth_store.load_split(),
])
  .then(() => {
    app.mount('#app');
  });
