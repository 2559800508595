<script setup>
import { cloneDeep, uniqBy } from 'lodash-es';
import { onKeyStroke } from '@vueuse/core';
import useEmitter from '~/common/composables/useEmitter';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  // Denotes all the fields (available + selected)
  items: {
    type: Array,
    required: true,
    default: () => [],
  },
  is_loading_items: {
    type: Boolean,
    default: false,
  },
  // Denotes the fields that are present on the right side
  selected_items: {
    type: Array,
    required: true,
    default: () => [],
  },
  // Denotes if the items and selected_items are objects or strings
  items_format: {
    type: String,
    default: 'object',
  },
  // Denotes the key that is used to display the texts
  label_key: {
    type: String,
    required: false,
    default: 'name',
  },
  // Denotes the key that is used to access the values
  value_key: {
    type: String,
    required: false,
    default: 'uid',
  },
  // Denotes the key that is used to access the children
  children_key: {
    type: String,
    required: false,
    default: 'children',
  },
  // ---------------------------------------------------
  // Denotes the texts that are used in the component
  texts: {
    type: Object,
    default: () => {
      const $t = inject('$t');
      return {
        heading: $t('Customize columns'),
        left_heading: $t('Available fields'),
        right_heading: $t('Added fields'),
      };
    },
  },
  // Used to v-bind to the HawkModalTemplate component
  options: {
    type: Object,
    default: () => ({}),
  },
  // Denotes if the modal should close on save
  close_on_save: {
    type: Boolean,
    default: true,
  },
  // A function to do some action on save
  update: {
    type: Function,
    default: () => {},
  },
  // A number used to restrict the number of selected_items upto this number.
  max_selected_items: {
    type: Number,
    default: Number.MAX_SAFE_INTEGER,
  },
  // A number used to specify the minimum number of selected_items that need to be selected.
  min_selected_items: {
    type: Number,
    default: 0,
  },
  // Denotes if the column can be pinned
  has_field_pin_option: {
    type: Boolean,
    default: false,
  },
  // Used to hide/disable features
  hidden_features: {
    type: Array,
    validator(value) {
      const allowed_values = ['arrow-up', 'arrow-down', 'arrow-left', 'arrow-right', 'left-search', 'right-search', 'clear-all', 'left-column'];
      return value.every(item => allowed_values.includes(item));
    },
    default: () => [],
  },
});

const emit = defineEmits(['close', 'activeFieldsChange', 'clickOutside']);

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const { $toast, $t } = useCommonImports();
const emitter = useEmitter();

const available_fields_ref = ref('');
const active_fields_ref = ref('');

const state = reactive({
  refresh_key: 0,
  __all_fields: [],
  available_fields: [],
  active_fields: cloneDeep(props.selected_items),
  selected_active_fields: [],
  selected_available_fields: [],
  active_groups: {},
  shift_range_start_available_fields: -1,
  shift_range_end_available_fields: -1,
  shift_range_start_active_fields: -1,
  shift_range_end_active_fields: -1,
  search_available_fields: '',
  search_active_fields: '',
  filtered_available_fields: [],
  filtered_active_fields: [],
  available_fields_scroll_position: 0,
  active_fields_scroll_position: 0,
  is_saving: false,
});

emitter.on('selection_add', (payload) => {
  selectAvailableFields(payload.event, payload.field);
});

emitter.on('active_groups_changed', (payload) => {
  if (payload.type === 'add')
    if (!state.active_groups[payload.level])
      state.active_groups[payload.level] = [payload.value];
    else
      state.active_groups[payload.level].push(payload.value);
  else
    state.active_groups[payload.level] = state.active_groups[payload.level].filter(value => value !== payload.value);
});

const is_nested = computed(() => state.__all_fields.some(field => field[props.children_key]));

onBeforeUnmount(() => {
  emitter.off('selection_add');
  emitter.off('active_groups_changed');
});

watch(() => [state.search_available_fields, state.available_fields], (newVal, oldVal) => {
  if (oldVal[1] !== newVal[1]) {
    state.available_fields_scroll_position = available_fields_ref.value.scrollTop;
    state.active_fields_scroll_position = active_fields_ref.value.scrollTop;
    nextTick(() => {
      if (available_fields_ref.value)
        available_fields_ref.value.scrollTop = state.available_fields_scroll_position;
      if (active_fields_ref.value)
        active_fields_ref.value.scrollTop = state.active_fields_scroll_position;
    });
  }
  if (newVal[0].length) {
    state.active_groups = {};
    state.filtered_available_fields = state.available_fields;
    state.filtered_available_fields = filterAvailableFields(state.filtered_available_fields, newVal[0]);
    addActiveGroups(state.filtered_available_fields);
    state.selected_available_fields = [];
  }
  else if (newVal[0] !== oldVal[0]) {
    state.active_groups = {};
  }
});

watch(() => [state.search_active_fields, state.active_fields], (value) => {
  if (value[0].length) {
    state.filtered_active_fields = [];
    state.active_fields.forEach((field) => {
      if (field[props.label_key].toLowerCase().includes(value[0].toLowerCase()))
        state.filtered_active_fields.push(field);
    });
    state.selected_active_fields = [];
  }
});

watch(() => props.selected_items, () => {
  if (props.items_format === 'string')
    state.active_fields = props.selected_items.map((item) => {
      return {
        [props.value_key]: item,
        [props.label_key]: item,
      };
    });
  else
    state.active_fields = props.selected_items;

  state.active_fields = state.active_fields ?? [];
  for (const field of state.active_fields) {
    field._added = true;
    addActiveProperty(field[props.value_key], state.__all_fields);
  }
  recalculateAvailableFields();
});

watch(() => props.items, (value) => {
  if (props.items_format === 'string') {
    state.__all_fields = value.map((item) => {
      return {
        [props.value_key]: item,
        [props.label_key]: item,
      };
    });
    state.active_fields = props.selected_items.map((item) => {
      return {
        [props.value_key]: item,
        [props.label_key]: item,
      };
    });
  }
  else {
    state.__all_fields = cloneDeep(value);
  }

  state.active_fields = state.active_fields ?? [];
  for (const field of state.active_fields)
    addActiveProperty(field[props.value_key], state.__all_fields);
  recalculateAvailableFields();
}, { immediate: true });

watch(() => state.refresh_key, (newVal, oldVal) => {
  if (oldVal !== newVal) {
    state.available_fields_scroll_position = available_fields_ref.value.scrollTop;
    nextTick(() => {
      if (available_fields_ref.value)
        available_fields_ref.value.scrollTop = state.available_fields_scroll_position;
    });
  }
});

function addActiveProperty(value, fields) {
  for (const field of fields) {
    if (field[props.children_key])
      addActiveProperty(value, field[props.children_key]);

    if (field[props.value_key] === value)
      field._added = true;
    else if (!field[props.children_key] && !field._added)
      field._added = false;
  }
}

function copyInactiveFields(target) {
  let sanitized_target = cloneDeep(target);

  Object.keys(sanitized_target).forEach((key) => {
    if (typeof sanitized_target[key] === 'object' && sanitized_target[key] !== null && !sanitized_target[key]?._added)
      sanitized_target[key] = copyInactiveFields(sanitized_target[key]);
    else if (sanitized_target[key]?._added)
      delete sanitized_target[key];
  });

  if (Array.isArray(sanitized_target))
    sanitized_target = Object.values(sanitized_target);

  return sanitized_target;
}

function recalculateAvailableFields(event) {
  if (event?.added?.element?.[props.value_key])
    toggleField(event.added.element[props.value_key], state.__all_fields, false);
  state.available_fields = copyInactiveFields(state.__all_fields);
  state.refresh_key++;
}

function handleShiftSelection(value, total_list, selected_list, shift_range_start, shift_range_end) {
  if (state[selected_list].length === 0) {
    state[shift_range_start] = value;
    state[shift_range_end] = value;
  }
  else {
    if (state[shift_range_start] === -1)
      state[shift_range_start] = state[selected_list][0][props.value_key];
    state[shift_range_end] = value;
  }

  if (is_nested.value && selected_list === 'selected_available_fields') {
    const current_group_values = findColumn(value, state.available_fields, true);

    let start_index = current_group_values.findIndex(item => item[props.value_key] === state[shift_range_start]);
    let end_index = current_group_values.findIndex(item => item[props.value_key] === state[shift_range_end]);
    if (end_index < start_index)
      [start_index, end_index] = [end_index, start_index];

    state[selected_list] = current_group_values.slice(start_index, end_index + 1);
  }
  else {
    let start_index = total_list.findIndex(item => item[props.value_key] === state[shift_range_start]);
    let end_index = total_list.findIndex(item => item[props.value_key] === state[shift_range_end]);
    if (end_index < start_index)
      [start_index, end_index] = [end_index, start_index];
    state[selected_list] = total_list.slice(start_index, end_index + 1);
  }
}

function selectAvailableFields(event, field) {
  if (event.shiftKey) {
    handleShiftSelection(field[props.value_key], state.available_fields, 'selected_available_fields', 'shift_range_start_available_fields', 'shift_range_end_available_fields');
    return;
  }
  const does_include = state.selected_available_fields?.find?.(col => col[props.value_key] === field[props.value_key]);

  if (does_include) {
    if (state.selected_available_fields.length > 1)
      state.selected_available_fields = [field];

    else
      state.selected_available_fields = state.selected_available_fields.filter(
        n => n[props.value_key] !== field[props.value_key],
      );

    return;
  }

  if (event.ctrlKey || event.metaKey)
    state.selected_available_fields.push(field);

  else
    state.selected_available_fields = [field];

  state.shift_range_start_available_fields = field[props.value_key];
}

function selectActiveColumns(event, field) {
  if (event.shiftKey) {
    handleShiftSelection(field[props.value_key], state.active_fields, 'selected_active_fields', 'shift_range_start_active_fields', 'shift_range_end_active_fields');
    return;
  }

  const does_include = state.selected_active_fields?.find?.(col => col[props.value_key] === field[props.value_key]);

  if (does_include) {
    if (state.selected_active_fields.length > 1)
      state.selected_active_fields = [field];

    else
      state.selected_active_fields = state.selected_active_fields.filter(
        n => n[props.value_key] !== field[props.value_key],
      );

    return;
  }

  if (event.ctrlKey || event.metaKey)
    state.selected_active_fields.push(field);
  else
    state.selected_active_fields = [field];

  state.shift_range_start_active_fields = field[props.value_key];
}

function findColumn(value, fields, group = false) {
  for (const field of fields) {
    if (field[props.children_key]) {
      const found = findColumn(value, field[props.children_key], group);
      if (found)
        return found;
    }

    if (field[props.value_key] === value) {
      if (group)
        return fields;
      return field;
    }
  }

  return null;
}

function findPath(value, fields, groups = []) {
  for (const field of fields) {
    if (field[props.children_key]) {
      groups.push(field);
      const path = findPath(value, field[props.children_key], groups);
      if (path)
        return path;
      groups.pop();
    }

    if (field[props.value_key] === value)
      return groups;
  }
}

function toggleField(value, fields, new_state) {
  for (const field of fields) {
    if (field[props.children_key])
      toggleField(value, field[props.children_key], new_state);

    if (!new_state && field.pin)
      field.pin = false;
    if (field[props.value_key] === value)
      field._added = new_state;
  }
}

function activateFields(index) {
  let index_of_push = index ?? state.active_fields.length;
  for (const field of state.selected_available_fields) {
    if (field?._added)
      continue;
    if (field?.pin)
      field.pin = false;
    toggleField(field[props.value_key], state.__all_fields, true);
    recalculateAvailableFields();
    state.active_fields.splice(index_of_push, 0, { ...field });
    index_of_push++;
  }
  state.selected_active_fields = [];
  state.selected_available_fields = [];
  emit('activeFieldsChange', state.active_fields);
}

function deactivateFields() {
  for (const field of state.selected_active_fields) {
    toggleField(field[props.value_key], state.__all_fields, false);
    recalculateAvailableFields();
    state.active_fields = state.active_fields.filter(
      col => col[props.value_key] !== field[props.value_key],
    );
  }
  state.selected_active_fields = [];
  state.selected_available_fields = [];
  emit('activeFieldsChange', state.active_fields);
}

function changeIndex(val) {
  if (
    state.selected_active_fields.length > 1
    || state.selected_active_fields?.[0]?.pin
  )
    return;
  const arr = state.active_fields;
  for (const field of state.selected_active_fields) {
    const from = arr.findIndex(col => col[props.value_key] === field[props.value_key]);
    const to = from + val;
    if (to < 0 || to > state.selected_active_fields || (to === 0 && state.active_fields?.[0]?.pin))
      continue;
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
  }
  emit('activeFieldsChange', state.active_fields);
}

async function saveColumns() {
  try {
    state.is_saving = true;
    await props.update(props?.items_format === 'string'
      ? state.active_fields.map(item => item[props.value_key])
      : state.active_fields);
    state.is_saving = false;
    if (props.close_on_save)
      emit('close');
  }
  catch (err) {
    state.is_saving = false;
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}

function getLeafElements(current_element) {
  const leaf_elements = [];
  if (current_element?.[props.children_key]?.length)
    for (const child of current_element[props.children_key])
      if (child[props.children_key])
        leaf_elements.push(...getLeafElements(child));
      else
        leaf_elements.push(child);
  else leaf_elements.push(current_element);

  return leaf_elements;
}

function onChange(event) {
  if (event.added) {
    state.active_fields = uniqBy(state.active_fields, props.value_key).map((field) => {
      if (field.pin && event.added.element[props.value_key] === field[props.value_key])
        field.pin = false;
      return field;
    });
    if (event.added.element?.[props.children_key]?.length) {
      const leaf_elements = getLeafElements(event.added.element);
      state.active_fields = state.active_fields.filter(col => !col?.[props.children_key]?.length);
      state.selected_available_fields = leaf_elements;
      activateFields(event.added.newIndex);
      state.active_fields = uniqBy(state.active_fields, props.value_key);
    }
    else if (state.selected_available_fields.length > 1) {
      activateFields(event.added.newIndex);
      state.active_fields = uniqBy(state.active_fields, props.value_key);
    }
    else {
      toggleField(event.added.element[props.value_key], state.__all_fields, true);
      recalculateAvailableFields();
    }
  }
  else if (event.removed) {
    if (state.selected_active_fields.length > 1) {
      deactivateFields();
    }
    else {
      toggleField(event.removed.element[props.value_key], state.__all_fields, false);
      recalculateAvailableFields();
    }
  }
  emit('activeFieldsChange', state.active_fields);
}

function clearAll() {
  state.selected_active_fields = state.search_active_fields.length
    ? state.filtered_active_fields
    : state.active_fields;
  deactivateFields();
}

function filterAvailableFields(fields, search_value) {
  const filtered_fields = [];
  for (const field of fields) {
    if (field[props.label_key].toLowerCase().includes(search_value.toLowerCase())) {
      filtered_fields.push(field);
      continue;
    }
    if (field[props.children_key]) {
      const filtered_children = filterAvailableFields(field[props.children_key], search_value);
      if (filtered_children.length > 0) {
        const cloned_field = { ...field };
        cloned_field[props.children_key] = filtered_children;
        filtered_fields.push(cloned_field);
      }
    }
  }

  return filtered_fields;
}

function addActiveGroups(fields, level = 0) {
  for (const field of fields)
    if (field[props.children_key]) {
      if (!state.active_groups?.[level])
        state.active_groups[level] = [];

      state.active_groups[level].push(field[props.value_key]);
      addActiveGroups(field[props.children_key], level + 1);
    }
}

function findPreviousField(current_field, fields) {
  let previousLeaf = null;

  function dfs(nodes) {
    for (const node of nodes) {
      if (node[props.value_key] === current_field[props.value_key])
        return true;

      if (!node[props.children_key])
        previousLeaf = node;

      if (node[props.children_key] && dfs(node[props.children_key]))
        return true;
    }
    return false;
  }

  dfs(fields);
  return previousLeaf;
}

function findNextField(current_field, fields) {
  let foundCurrent = false;

  function dfs(nodes) {
    for (const node of nodes) {
      if (foundCurrent && !node[props.children_key])
        return node;

      if (node[props.value_key] === current_field[props.value_key])
        foundCurrent = true;

      if (node[props.children_key]) {
        const nextLeaf = dfs(node[props.children_key]);
        if (nextLeaf)
          return nextLeaf;
      }
    }
    return null;
  }

  return dfs(fields);
}

function handleColumnPin(field) {
  state.active_fields = state.active_fields.map((col) => {
    if (col[props.value_key] === field[props.value_key])
      col.pin = !col.pin;
    else
      col.pin = false;
    return col;
  });
  const index = state.active_fields
    .findIndex(field_val => field_val[props.value_key] === field[props.value_key]);
  if (index !== -1) {
    state.active_fields.splice(index, 1);
    state.active_fields.unshift(field);
  }
}

onMounted(() => {
  const index = state.active_fields.findIndex(field_val => field_val.pin === true);
  const field = state.active_fields[index];
  if (index !== -1) {
    state.active_fields.splice(index, 1);
    state.active_fields.unshift(field);
  };
});

onKeyStroke('ArrowRight', () => {
  if (state.selected_available_fields.length) {
    const next_field = findNextField(state.selected_available_fields[state.selected_available_fields.length - 1], state.available_fields);
    const previous_field = findPreviousField(state.selected_available_fields[0], state.available_fields);
    activateFields();
    if (next_field !== null)
      state.selected_available_fields = [next_field];
    else if (previous_field !== null)
      state.selected_available_fields = [previous_field];
    if (state.selected_available_fields[0])
      state.active_groups = findPath(state.selected_available_fields[0][props.value_key], state.available_fields).map(item => item?.[props.value_key]);
  }
});

onKeyStroke('ArrowLeft', () => {
  if (state.selected_active_fields.length) {
    const index_of_first_active_field = state.active_fields.findIndex(item => item[props.value_key] === state.selected_active_fields[0][props.value_key]);
    const index_of_last_active_field = state.active_fields.findIndex(item => item[props.value_key] === state.selected_active_fields[state.selected_active_fields.length - 1][props.value_key]);
    let field = state.selected_active_fields;
    if (index_of_last_active_field !== state.active_fields.length - 1)
      field = [state.active_fields[index_of_last_active_field + 1]];
    else if (index_of_first_active_field > 0)
      field = [state.active_fields[index_of_first_active_field - 1]];
    deactivateFields();
    state.selected_active_fields = field;
  }
});

onKeyStroke('ArrowDown', (event) => {
  if (event.ctrlKey || event.metaKey) {
    changeIndex(+1);
  }
  else if (state.selected_active_fields.length === 1) {
    const index_of_current_active_field = state.active_fields.findIndex(item => item[props.value_key] === state.selected_active_fields[0][props.value_key]);
    if (index_of_current_active_field !== state.active_fields.length - 1)
      state.selected_active_fields = [state.active_fields[index_of_current_active_field + 1]];
    nextTick(() => {
      document.querySelector('.selected-active-field')?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
    });
  }
  else if (state.selected_available_fields.length === 1) {
    const next_field = findNextField(state.selected_available_fields[0], state.available_fields);
    if (next_field !== null) {
      state.selected_available_fields = [next_field];
      state.active_groups = findPath(state.selected_available_fields[0][props.value_key], state.available_fields).map(item => item?.[props.value_key]);
      nextTick(() => {
        document.querySelector('.selected-available-field')?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
      });
    }
  }
});

onKeyStroke('ArrowUp', (event) => {
  if (event.ctrlKey || event.metaKey) {
    changeIndex(-1);
  }
  else if (state.selected_active_fields.length === 1) {
    const index_of_current_active_field = state.active_fields.findIndex(item => item[props.value_key] === state.selected_active_fields[0][props.value_key]);
    if (index_of_current_active_field > 0)
      state.selected_active_fields = [state.active_fields[index_of_current_active_field - 1]];
    nextTick(() => {
      document.querySelector('.selected-active-field')?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
    });
  }
  else if (state.selected_available_fields.length === 1) {
    const previous_field = findPreviousField(state.selected_available_fields[0], state.available_fields);
    if (previous_field !== null) {
      state.selected_available_fields = [previous_field];
      state.active_groups = findPath(state.selected_available_fields[0][props.value_key], state.available_fields).map(item => item?.[props.value_key]);
    }
    nextTick(() => {
      document.querySelector('.selected-available-field')?.scrollIntoView?.({ behavior: 'smooth', block: 'nearest' });
    });
  }
});
</script>

<template>
  <HawkModalTemplate v-bind="options" @click-outside="$emit('clickOutside')" @close="$emit('close')">
    <template #title_text>
      <div class="text-lg font-semibold text-gray-900">
        {{ props.texts.heading }}
      </div>
    </template>
    <hawk-loader v-if="is_loading_items" class="w-[50rem] h-[45vh] m-0" />
    <div v-show="!is_loading_items">
      <div :key="state.available_fields" :class="{ 'w-[59.5rem]': !props.hidden_features.includes('left-column') }">
        <div class="flex items-center gap-6">
          <div v-if="!props.hidden_features.includes('left-column')" class="w-full">
            <div class="mb-2 text-sm font-semibold text-gray-900">
              {{ props.texts.left_heading }}
            </div>
            <HawkSearchInput
              v-if="!props.hidden_features.includes('left-search')"
              v-model="state.search_available_fields"
              :full_width="true"
              :debounce_time="500"
              class="mb-2"
            />
            <!-- Display the nested available columns -->
            <div ref="available_fields_ref" class="border border-gray-300 rounded-lg py-3 px-4 scrollbar h-[40vh]">
              <FieldsSelectorLeftSection
                :key="state.refresh_key"
                class="h-full"
                :available_fields="
                  state.search_available_fields.length
                    ? state.filtered_available_fields
                    : state.available_fields
                "
                :label_key="props.label_key"
                :value_key="props.value_key"
                :children_key="props.children_key"
                :selected_available_fields="state.selected_available_fields"
                :active_groups="state.active_groups"
                :level="0"
                @reject="recalculateAvailableFields($event)"
              >
                <template #item="{ label, is_selected, item }">
                  <slot name="left_item" :label="label" :is_selected="is_selected" :item="item">
                    <div
                      class="py-2 pl-8 pr-3 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 break-all"
                      :class="{ 'bg-gray-100 hover:!bg-gray-100 selected-available-field': is_selected }"
                    >
                      {{ label }}
                    </div>
                  </slot>
                </template>
                <template #parent_item="{ label, is_active, item }">
                  <slot name="parent_item" :label="label" :is_active="is_active" :item="item">
                    <IconHawkChevronDown v-if="is_active" />
                    <IconHawkChevronRight v-else />
                    <span class="ml-2 text-sm font-medium text-gray-700">
                      {{ label }}
                    </span>
                  </slot>
                </template>
              </FieldsSelectorLeftSection>
            </div>
          </div>
          <div v-if="!props.hidden_features.includes('arrow-right') && !props.hidden_features.includes('arrow-left')" class="w-8">
            <div
              v-if="!props.hidden_features.includes('arrow-right')"
              class="flex items-center justify-center w-8 h-8 mb-6 bg-gray-100 rounded-full cursor-not-allowed"
              :class="{ 'cursor-pointer': state.selected_available_fields.length }"
              @click="activateFields(state.active_fields.length)"
            >
              <IconHawkArrowRight class="text-gray-600" />
            </div>
            <div
              v-if="!props.hidden_features.includes('arrow-left')"
              class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full cursor-not-allowed"
              :class="{ 'cursor-pointer': state.selected_active_fields?.length }"
              @click="deactivateFields"
            >
              <IconHawkArrowLeft class="text-gray-600" />
            </div>
          </div>
          <div class="w-full">
            <div class="mb-2 text-sm font-semibold text-gray-900">
              {{ props.texts.right_heading }}
            </div>
            <HawkSearchInput
              v-if="!props.hidden_features.includes('right-search')"
              v-model="state.search_active_fields"
              :full_width="true"
              :debounce_time="500"
              class="mb-2"
            />
            <div ref="active_fields_ref" class="border border-gray-300 rounded-lg py-3 px-4 scrollbar h-[40vh]">
              <draggable
                :list="
                  state.search_active_fields.length
                    ? state.filtered_active_fields
                    : state.active_fields
                "
                :group="{ name: 'columns' }"
                :item-key="props.value_key"
                class="h-full"
                draggable=".is_draggable"
                @change="onChange"
              >
                <template #item="{ element: column }">
                  <div
                    :class="
                      {
                        is_draggable: (
                          !has_field_pin_option
                          || state.active_fields?.find?.(field => field.pin === true)?.[value_key] !== column[value_key]),
                      }"
                    @click="selectActiveColumns($event, column)"
                  >
                    <slot
                      name="right_item"
                      :label="column?.[label_key]"
                      :is_selected="state.selected_active_fields?.find?.(col => col[value_key] === column[value_key])"
                      :item="column"
                    >
                      <div
                        class="p-2 pl-4 text-sm font-medium text-gray-700 cursor-pointer hover:bg-gray-50 break-all group"
                        :class="{
                          'bg-gray-100 hover:!bg-gray-100 selected-active-field': state.selected_active_fields?.find?.(col => col[value_key] === column[value_key]),
                        }"
                      >
                        <div class="flex items-center justify-between">
                          {{ column?.[label_key] }}
                          <div
                            v-if="has_field_pin_option"
                            class="pl-2  text-base pin"
                            :class="[
                              state.active_fields?.find?.(field => field.pin === true)?.[value_key] === column[value_key]
                                ? 'visible'
                                : 'invisible group-hover:visible',
                            ]"
                            @click.stop="handleColumnPin(column)"
                          >
                            <IconHawkPinnedOne
                              v-if="state.active_fields?.find?.(field => field.pin === true)?.[value_key] === column[value_key]"
                              class="w-4 h-4"
                            />
                            <IconHawkPinTwoInactive v-else class="w-4 h-4" />
                          </div>
                        </div>
                      </div>
                    </slot>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
          <div
            v-if="!props.hidden_features.includes('arrow-up') || !props.hidden_features.includes('arrow-down')"
            class="w-8"
          >
            <div
              v-if="!props.hidden_features.includes('arrow-up')"
              class="flex items-center justify-center w-8 h-8 mb-6 bg-gray-100 rounded-full cursor-pointer"
              @click="changeIndex(-1)"
            >
              <IconHawkArrowUp class="text-gray-600" />
            </div>
            <div
              v-if="!props.hidden_features.includes('arrow-down')"
              class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full cursor-pointer"
              @click="changeIndex(+1)"
            >
              <IconHawkArrowDown class="text-gray-600" />
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center mt-3" :class="[$slots.additional_content ? 'justify-between' : 'justify-end']">
        <slot name="additional_content" />
        <HawkButton
          v-if="!props.hidden_features.includes('clear-all')"
          :class="{
            'mr-14': !props.hidden_features.includes('arrow-up') || !props.hidden_features.includes('arrow-down'),
          }"
          type="text"
          @click="clearAll"
        >
          {{ $t('Clear all') }}
        </HawkButton>
      </div>
      <div class="flex justify-end">
        <div
          v-if="state.active_fields.length < props.min_selected_items"
          class="text-sm font-regular text-error-500"
          :class="{
            'mr-14': !props.hidden_features.includes('arrow-up') || !props.hidden_features.includes('arrow-down'),
          }"
        >
          {{ $t('A minimum of') }} {{ props.min_selected_items }} {{ $t('field(s) have to be selected') }}.
        </div>
        <div
          v-if="state.active_fields.length > props.max_selected_items"
          class="text-sm font-regular text-error-500"
          :class="{
            'mr-14': !props.hidden_features.includes('arrow-up') || !props.hidden_features.includes('arrow-down'),
          }"
        >
          {{ $t('A maximum of') }} {{ props.max_selected_items }} {{ $t('field(s) can be selected') }}.
        </div>
      </div>
    </div>
    <template #footer>
      <slot name="footer">
        <Vueform size="sm">
          <div class="flex justify-end w-full col-span-full">
            <ButtonElement
              name="cancel"
              class="mr-4"
              :secondary="true"
              @click="emit('close')"
            >
              {{ $t('Cancel') }}
            </ButtonElement>
            <ButtonElement
              name="save"
              :loading="state.is_saving"
              :disabled="
                state.active_fields.length > props.max_selected_items
                  || state.active_fields.length < props.min_selected_items"
              @click="saveColumns"
            >
              {{ $t('Save') }}
            </ButtonElement>
          </div>
        </Vueform>
      </slot>
    </template>
  </HawkModalTemplate>
</template>
