<script setup>
import { omit } from 'lodash-es';
import RuleFields from '~/forms/components/form-workflows/sidebar/conditional-block/step-setting-rules.vue';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';

const props = defineProps({
  form: {
    type: Object,
    default() {
      return {};
    },
  },
  step_number: {
    type: Number,
    default: -1,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
  submit_function: {
    type: Function,
    default: null,
  },
  rule_id_to_open: {
    type: String,
    default: null,
  },
});

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const form_workflow_store = useFormWorkflowStore();
const $toast = inject('$toast');
const $t = inject('$t');
const form_data = ref({});
const rule_list = ref([]);
const otherwise_condition = ref(null);
const rule_opened = ref(null);
const is_loading = ref(false);
const default_data = computed(() => {
  return (props.form && Object.keys(props.form).length)
    ? props.form
    : {
        name: 'Conditional block',
        description:
                'Route the form through different steps based on predefined conditions',
        conditional_logic: props.form?.conditional_logic ?? {},
        index: props.step_number,
      };
});
const fields_options = computed(() => {
  return form_workflow_store.get_fields(props.step_number).filter(field => !(['attachment', 'check_item', 'auto_number', 'multi_text'].includes(field.type) || ['info', 'formula'].includes(field.properties?.type)));
});

function addCondition() {
  if (fields_options.value.length) {
    rule_list.value.push({
      name: '',
      description: '',
      rules: [],
      logic: { type: 'and' },
    });
    rule_opened.value = rule_list.value.length - 1;
  }
  else {
    $toast({ text: 'No fields available', type: 'error' });
  }
}

const invalid = computed(() => !form_data.value.name.length || !rule_list.value.length);

function deleteRule() {
  rule_list.value.splice(rule_opened.value, 1);
  rule_opened.value = null;
  form_workflow_store.is_sidebar_form_dirty = true;
}

function updateRuleList(data) {
  const rule = { uid: crypto.randomUUID(), ...data };
  rule_list.value[rule_opened.value] = {
    ...rule,
    rules: rule.rules.reduce((acc, curr, index) => {
      acc[index + 1] = curr;
      return acc;
    }, {}),
  };
  rule_opened.value = null;
  form_workflow_store.is_sidebar_form_dirty = true;
}

async function save() {
  is_loading.value = true;
  if (props.submit_function) {
    const conditional_logic = rule_list.value.reduce((acc, curr, index) => {
      acc[index + 1] = curr;
      return acc;
    }, {});
    conditional_logic.otherwise = otherwise_condition.value || {
      uid: crypto.randomUUID(),
      name: 'Otherwise',
      rule: {},
      logic: {},
    };
    await props.submit_function(
      {
        ...form_data.value,
        conditional_logic,
      },
    );
  }
  is_loading.value = false;
}

function showToast(e) {
  logger.log(e);
  $toast(e);
}

function init() {
  form_data.value = default_data.value;
  rule_list.value = Object.values(omit(default_data.value.conditional_logic, ['otherwise']));
  otherwise_condition.value = default_data.value.conditional_logic?.otherwise;
  if (props.rule_id_to_open)
    rule_opened.value = rule_list.value.findIndex(rule => rule.uid === props.rule_id_to_open);
}
init();
</script>

<template>
  <RuleFields
    v-if="rule_opened !== null"
    :class="{ 'pointer-events-none': is_disabled }"
    :rule="rule_list[rule_opened]"
    :step_number="step_number"
    :is_disabled="is_disabled"
    @delete="deleteRule"
    @save="updateRuleList"
    @close="rule_opened = null"
    @toast="showToast"
  />
  <div v-show="rule_opened === null" class="col-span-12" :class="{ 'pointer-events-none': is_disabled }">
    <div class="flex justify-between items-start mb-10">
      <div class="w-80">
        <div class="text-lg font-semibold">
          {{ $t('Conditional Block') }}
        </div>
        <div class="text-xs">
          {{ $t('Setup conditions to automatically execute business workflows when certain conditions occur') }}
        </div>
      </div>
      <HawkButton type="plain" class="pointer-events-auto" @click="$emit('close')">
        <IconHawkXClose class="text-gray-500" />
      </HawkButton>
    </div>
    <Vueform
      v-model="form_data" :sync="true" size="sm" :display-errors="false"
      :columns="{
        lg: {
          container: 12,
          label: 3,
          wrapper: 12,
        },
      }"
      :messages="{ required: $t('This field is required') }"
      @change="form_workflow_store.is_sidebar_form_dirty = true"
    >
      <div class="col-span-12">
        <TextElement name="name" :label="$t('Name')" class="mb-5" rules="required" :readonly="is_disabled" />
        <TextareaElement name="description" :label="$t('Description')" class="mb-5" :readonly="is_disabled" />
      </div>
    </Vueform>
    <hr class="mb-5">
    <div class="text-[14px] font-semibold mb-5">
      {{ $t('Conditions') }}
    </div>
    <draggable
      v-model="rule_list"
      class="min-w-full"
      tag="div"
      draggable=".is_draggable"
      handle=".move"
    >
      <template #item="{ element: rule, index: i }">
        <div
          :class="{ hidden: rule.name === 'Otherwise' }"
          class="group flex items-center justify-between px-2 mb-2 text-sm cursor-pointer rounded-lg w-min-full hover:bg-gray-50 is_draggable"
          @click="rule_opened = i"
        >
          <div class="flex items-center text-sm">
            <icon-hawk-drag-icon class="cursor-move move flex w-3.5 h-3.5 text-gray-400 mr-2 invisible group-hover:visible" @click.stop="" />
            <div class="text-[14px] group-hover:font-medium pointer-events-auto">
              <HawkText :content="rule.name" length="50" />
            </div>
          </div>
          <HawkButton class="invisible group-hover:visible" type="plain" @click.stop="rule_list.splice(i, 1)">
            <IconHawkTrashThree class=" text-gray-600" />
          </HawkButton>
        </div>
      </template>
    </draggable>
    <HawkButton v-if="rule_list.length > 0" type="link" class="px-2" @click="addCondition()">
      <IconHawkPlus
        class="text-primary-700 w-5 h-4.75 mx-1"
      />
      <div class="font-semibold">
        {{ $t('Add Condition') }}
      </div>
    </HawkButton>

    <div v-else class="rounded-xl border px-4 py-5 text-gray-900 border-warning-300 bg-warning-25">
      <div class="flex items-start">
        <div class="mr-2">
          <div class="w-10 h-10 rounded-full flex justify-center items-center mr-2 bg-warning-100">
            <IconHawkAlertCircle class="w-5 h-5 text-warning-600" />
          </div>
        </div>
        <div>
          <h4 class="mb-1 text-[14px] font-medium leading-6 font-sans">
            {{ $t('Conditions') }}
          </h4>
          <p class="text-[12px] text-gray-600 font-sans">
            {{ $t('Create conditions and rules to trigger actions in your workflow.') }}
          </p>
        </div>
      </div>
      <HawkButton block class="mt-3" type="outlined" @click="addCondition()">
        <IconHawkPlus class="mr-1" />
        <div class="font-semibold">
          {{ $t('Add Condition') }}
        </div>
      </HawkButton>
    </div>
    <hr class="my-4">
    <div v-if="!is_disabled" class="flex justify-end">
      <HawkButton class="mr-2" type="outlined" @click="$emit('close')">
        {{ $t('Cancel') }}
      </HawkButton>
      <HawkButton :disabled="invalid" :loading="is_loading" @click="save">
        {{ $t('Save') }}
      </HawkButton>
    </div>
  </div>
</template>
