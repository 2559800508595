<script setup>
import { ref } from 'vue';
import { useModal } from 'vue-final-modal';
import FeatureTypePopup from './feature-type-popup.vue';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import { useTerraStore } from '~/terra/store/terra.store.js';

const props = defineProps({
  ftg: {
    type: Object,
    default: null,
  },
  inactive_feature_types: {
    type: Array,
    default: () => [],
  },
  on_save: {
    type: Function,
    required: true,
  },
  features_list: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['deleteFeature', 'close', 'cancel']);

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const $t = inject('$t');
const $services = inject('$services');
const { getStyles } = useTerraHelperComposable();
const terra_store = useTerraStore();
const route = useRoute();

const edit_feature_popup = useModal({
  component: FeatureTypePopup,
});
const delete_popup = useModal({
  component: HawkDeletePopup,
});

const form = ref(null);
const features_list = ref([]);

async function getData() {
  try {
    const { data } = (
      await $services.feature_type_groups.getAll({
        query: {
          pk: true,
          exclude_containers: route.params.id,
          org: terra_store.container?.organization || '',
        },
      })
    );
    return data.map(val => Object({
      label: val.name,
      value: val.uid,
    }));
  }
  catch (err) {
    logger.log('🚀 ~ file: ftg-popup.vue:62 ~ getData ~ err:', err);
    return [];
  }
}

function openFeature(feature = null) {
  edit_feature_popup.patchOptions({
    attrs: {
      feature,
      ftg: props.ftg,
      onClose() {
        edit_feature_popup.close();
      },
      onDelete() {
        deleteFeature(props.ftg, feature);
      },
      onAddFeature(data) {
        if (feature)
          features_list.value[feature.id] = data;
        else
          features_list.value.push({ ...data, id: features_list.value.length });
      },
    },
  });
  edit_feature_popup.open();
}

async function saveFeature(group_uid, data) {
  await terra_store.update_feature_type({
    feature_type_uid: null,
    group_uid,
    data,
    action: 'add',
  });
}

function deleteFeature(ftg, feature) {
  if (ftg) {
    edit_feature_popup.close();
    emit('deleteFeature', { ftg, feature });
  }
  else {
    delete_popup.patchOptions({
      attrs: {
        header: feature.name,
        content: $t('Are you sure you want to delete the feature type?'),
        onClose() {
          delete_popup.close();
        },
        confirm() {
          features_list.value.splice(feature.id, 1);
          delete_popup.close();
        },
      },
    });
    delete_popup.open();
  }
}

async function onMove(data) {
  try {
    if (props.ftg)
      await terra_store.moveFeatureTypes({
        oldIndex: data.moved.oldIndex - 1,
        newIndex: data.moved.newIndex - 1,
        element: data.moved.element,
      });
    else
      features_list.value = features_list.value.map((feature, i) => ({ ...feature, id: i }));
  }
  catch (err) {
    logger.log('🚀 ~ file: ftg-popup.vue:133 ~ onMove ~ err:', err);
  }
}

async function saveOrUpdate() {
  if (!props.ftg && form.value.group_type === 'assign_group') {
    await assign_ftg();
  }
  else {
    const group_id = await props.on_save(form.value.group_name);
    if (group_id)
      for (const feature of features_list.value.map((feature, i) => {
        delete feature.id;
        feature.order_index = i;
        feature.featureTypeGroup = group_id;
        return feature;
      }))
        await saveFeature(group_id, feature);
  }
  emit('cancel');
}

function updateForm() {
  if (props.ftg)
    form.value = {
      group_name: props.ftg.name,
    };
  else
    form.value = {
      group_name: '',
    };
}

async function assign_ftg() {
  try {
    await $services.feature_type_groups.assign_to_view({
      id: route.params.id,
      body: {
        featureTypeGroups: form.value.selected,
      },
    });
    const res = await $services.feature_type_groups.getAll({
      query: {
        pk: true,
        container: route.params.id,
      },
    });
    terra_store.terra_track_events('Assign class group');
    terra_store.ftg = res.data;
  }
  catch (err) {
    logger.log('🚀 ~ file: ftg-popup.vue:183 ~ const assign_ftg= ~ err:', err);
  }
}
watch(() => props.features_list, (val) => {
  features_list.value = val;
}, { immediate: true });
updateForm();
</script>

<template>
  <hawk-modal-container :width="400" content_class="rounded-lg min-w-[600px]">
    <Vueform
      v-if="form"
      v-model="form"
      :sync="true" size="sm" :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :endpoint="saveOrUpdate"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('cancel')">
          <template #title>
            {{ ftg ? $t("Edit Group") : $t("Create Group") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="form.group_type !== 'assign_group'">
          <RadiogroupElement
            v-if="!ftg"
            name="group_type"
            :items="[
              { value: 'add_group', label: $t('Add New Group') },
              { value: 'assign_group', label: $t('Assign Group') },
            ]"
            default="add_group"
            :inline="true"
            :add-classes="{
              RadiogroupRadio: {
                container: 'mr-5 flex text-[14px]',
                text: 'font-medium text-[14px]',
              },
            }"
            :remove-class="{
              wrapper: ['flex-col'],
            }"
          >
            <template #item="{ item }">
              <v-radio :value="item.value">
                {{ item.label }}
              </v-radio>
            </template>
          </RadiogroupElement>
          <template v-if="form.group_type === 'assign_group'">
            <TagsElement
              class="my-6"
              name="selected"
              :close-on-select="false"
              :search="true"
              :items="getData"
              :label="$t('Assign Groups')"
              :placeholder="$t('Select Groups')"
              :rules="['required', 'min:1']"
              input-type="search"
              autocomplete="off"
              :can-clear="false"
            />
          </template>
          <template v-else>
            <TextElement
              class="my-6"
              name="group_name"
              :placeholder="$t('Enter name')"
              :label="$t('Group name')"
              :rules="['required']"
            />
            <draggable
              :list="ftg ? ftg.featureTypes : features_list" class="min-w-full" tag="div"
              handle=".move"
              @change="onMove"
            >
              <template #item="{ element: feature }">
                <div
                  class="p-2 hover:bg-gray-100"
                >
                  <div
                    class="flex justify-between group cursor-pointer"
                    @click="terra_store.check_permission('modify_featuretypes') ? openFeature(feature) : null"
                  >
                    <div class="flex items-center">
                      <IconHawkDragIcon v-if="terra_store.check_permission('modify_featuretypes')" class="cursor-move move text-gray-400 h-[16px] w-[16px] pr-1 invisible group-hover:visible" @click.stop="" />

                      <div class="flex items-center">
                        <div
                          class="rounded-full w-3 h-3 bg-gray-400"
                          :style="getStyles(feature)"
                        />
                        <p
                          :class="{
                            'line-through': inactive_feature_types.includes(
                              feature.id,
                            ),
                          }"
                          style="width:2rem;white-space:nowrap"
                          class="text-[14px] font-medium text-gray-600 ml-3"
                        >
                          {{ feature.name }}
                        </p>
                      </div>
                    </div>
                    <div v-if="terra_store.check_permission('modify_featuretypes')" class="flex items-center invisible group-hover:visible">
                      <div

                        class="cursor-pointer mr-3"
                        @click.stop="openFeature(feature)"
                      >
                        <IconHawkPencilOne class="text-gray-500 h-4.5 w-4.5" />
                      </div>
                      <div
                        class="cursor-pointer mx-2"
                        @click.stop="deleteFeature(ftg, feature)"
                      >
                        <IconHawkTrashThree class="text-gray-500 w-4.5 h-4.5" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
            <div class="flex items-center cursor-pointer p-2 text-primary-600" @click="openFeature()">
              <IconHawkPlus class="mr-2 text-primary-600" />
              <div class="text-[14px] font-semibold">
                {{ $t('Add Class') }}
              </div>
            </div>
          </template>
        </hawk-modal-content>
        <hawk-modal-footer class="flex items-center" :class="{ 'justify-between': ftg }">
          <template #left>
            <hawk-button
              v-show="ftg"
              color="error"
              type="link"
              @click="$emit('deleteFtg', ftg)"
            >
              {{ $t('Delete Group') }}
            </hawk-button>
          </template>

          <template #right>
            <div class="flex justify-end" :class="{ 'w-full': !ftg }">
              <hawk-button class="mr-5" type="outlined" @click="$emit('cancel')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                :button-label="$t('Save')"
                :submits="true"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
