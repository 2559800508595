<script setup>
import { useModal } from 'vue-final-modal';
import { orderBy } from 'lodash-es';
import { useRoute, useRouter } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';

import TaskStatus from '~/tasks/components/atoms/task-status.vue';
import TaskForm from '~/tasks/components/molecule/task-form/task-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';

import { useTasksPermissions } from '~/tasks/composables/task-composables.js';

const Draggable = defineAsyncComponent(() => import('vuedraggable'));

const task_store = inject('task_store');
const { task_uid, update_task_uid } = inject('task-uid');
const loading = inject('task-loading');
const $t = inject('$t');
const $toast = inject('$toast');
const $date = inject('$date');
const is_template = inject('is-template');

const router = useRouter();
const route = useRoute();

const auth_store = useAuthStore();

const { checkTaskPermission } = useTasksPermissions();

const task = computed(() => {
  return task_store.get_current_task(task_uid.value) || {};
});

const subtasks = computed(() => {
  return orderBy(task_store.get_subtasks(task_uid.value), 'subtask_orderindex', 'asc');
});

const { open: openSubtaskForm, close: closeSubtaskForm, patchOptions } = useModal({
  component: TaskForm,
  slots: {
    title: '<p>New Subtask</p>',
  },

});

function handleSubtaskCreate() {
  patchOptions(
    {
      attrs: {
        task_type: 'subtask',
        parent_task: task.value,
        allow_multi_task_create: true,
        onClose() {
          closeSubtaskForm();
        },
        async on_submit(data) {
          try {
            const previous_subtask_uid = task.value.sub_task_uids.length ? task.value.sub_task_uids.at(-1) : '';
            if (data.has_multiple_tasks) {
              // Multiple subtasks
              const payload = [];
              data.payload.forEach((item) => {
                payload.push({
                  ...item,
                  ...{
                    target_element: task.value.target_element,
                    parent_task_uid: task.value.uid,
                    is_template,
                    previous_task_uid: previous_subtask_uid,
                    next_task_uid: null,
                  },
                });
              });
              // create sub tasks
              await task_store.create_sub_tasks({
                tasks: payload,
              });
            }
            else {
              // Single subtask
              const payload = {
                ...data,
                target_element: task.value.target_element,
                parent_task_uid: task.value.uid,
                is_template,
                previous_task_uid: previous_subtask_uid,
                next_task_uid: null,
              };
              // create subtask
              await task_store.create_sub_tasks({
                tasks: [payload],
              });
            }
            $toast({
              title: 'Subtasks created successfully',
              text: 'Your changes have been saved and your subtasks are added to the list',
              type: 'success',
              position: 'bottom-right',
            });
          }
          catch (error) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openSubtaskForm();
}

const { open: openDeletePopup, close: closeDeletePopup, patchOptions: patchDeleteOptions } = useModal({
  component: HawkDeletePopup,
});

function deleteHandler(subtask) {
  patchDeleteOptions(
    {
      attrs: {
        header: $t('Delete subtask'),
        content: `Are you sure you want to delete ${subtask.name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            await task_store.remove_tasks([subtask.uid]);
            closeDeletePopup();
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
              position: 'bottom-right',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

function changeSubtaskIndex(e) {
  const new_index = e.moved.newIndex;
  const old_index = e.moved.oldIndex;

  let prev_task;
  let next_task;
  if (new_index === 0) {
    prev_task = null;
    next_task = subtasks.value[0];
  }
  else if (new_index === subtasks.value.length - 1) {
    prev_task = subtasks.value[new_index];
    next_task = null;
  }
  else if (new_index > old_index) {
    prev_task = subtasks.value[new_index];
    next_task = subtasks.value[new_index + 1];
  }
  else {
    prev_task = subtasks.value[new_index - 1];
    next_task = subtasks.value[new_index];
  }

  const attributes = {
    previous_task_uid: prev_task ? prev_task.uid : null,
    next_task_uid: next_task ? next_task.uid : null,
  };

  let order;
  if (prev_task && next_task)
    order = (prev_task.subtask_orderindex + next_task.subtask_orderindex) / 2;
  else if (prev_task)
    order = prev_task.subtask_orderindex + 1;
  else if (next_task)
    order = next_task.subtask_orderindex - 1;

  task_store.update_sub_tasks(
    {
      task: {
        uid: e.moved.element.uid,
        subtask_orderindex: order,
        ...attributes,
      },
    },
  );
}
function openSubtask(uid) {
  task_store.create_navigation_context({
    current_task_uid: uid,
    task_nav_uids: subtasks.value.map(task => task.uid),
    strict: true,
  });
  task_store.set_navigation_meta({
    disable: {
      prev: task_store.current_task_index === 0,
      next: task_store.current_task_index === task_store.navigation_meta.task_nav_uids.length - 1,
    },
  });
  update_task_uid(uid);
  const task = btoa(JSON.stringify({
    id: uid,
    store_key: task_store.$id,
  }));
  router.replace({
    ...route,
    query: {
      [is_template ? 'template' : 'task']: task,
    },
  });
}
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <div class="text-lg text-gray-900 font-semibold">
        {{ $t('Subtasks') }}
      </div>
      <HawkButton
        v-if="checkTaskPermission({ permission: 'can_create_tasks', instance: task })"
        type="outlined"
        @click="handleSubtaskCreate"
      >
        <IconHawkPlusWhite class="text-white h-3" />
        <p class="whitespace-nowrap">
          {{ $t('Add Subtasks') }}
        </p>
      </HawkButton>
    </div>

    <HawkLoader v-if="loading" />

    <div v-else-if="subtasks.length" class="rounded-lg border">
      <Draggable
        tag="div"
        :list="subtasks"
        :disabled="!checkTaskPermission({ permission: 'can_modify', instance: task })"
        draggable=".is_draggable"
        item-key="element"
        @change="changeSubtaskIndex"
      >
        <template #item="{ element }">
          <div
            class="is_draggable border-b last:border-0"
          >
            <div
              class="grid grid-cols-12 py-5 hover:bg-gray-100 group cursor-pointer items-center"
              @click.stop="openSubtask(element.uid)"
            >
              <div class="col-span-4 grid grid-cols-12">
                <div class="col-span-2 flex items-center justify-center">
                  <IconHawkDragIcon
                    class="cursor-move invisible group-hover:visible text-sm w-6 h-4 text-gray-300"
                    :class="{ '!invisible': !checkTaskPermission({ permission: 'can_modify', instance: task }) }"
                  />
                </div>
                <div class="col-span-9 flex items-center">
                  <p class="text-sm font-medium text-gray-900 break-all">
                    {{ element.name }}
                  </p>
                </div>
              </div>
              <div class="col-span-3 text-sm text-gray-600">
                {{ element.category_name || '-' }}
              </div>
              <div class="col-span-3 ">
                <span class="text-sm whitespace-nowrap" :class="[element.due_date && new Date(element.due_date) <= new Date() ? 'text-[#B42318]' : 'text-[#475467]']">
                  {{ element.due_date ? $date(element.due_date, 'DD MMMM YYYY') : '-' }}
                </span>
              </div>
              <div class="col-span-1">
                <TaskStatus :status="element.status " />
              </div>
              <div class="col-span-1 invisible group-hover:visible flex items-center">
                <IconHawkTrashThree
                  v-show="checkTaskPermission({ permission: 'can_modify', instance: task })"
                  class="text-gray-600"
                  @click.stop="deleteHandler(element)"
                />
              </div>
            </div>
          </div>
        </template>
      </Draggable>
    </div>
    <HawkIllustrations v-else variant="mini" type="no-data" for="subtasks" />
  </div>
</template>
