<script setup>
import { cloneDeep, uniqBy } from 'lodash-es';
import { onKeyStroke } from '@vueuse/core';
import useEmitter from '~/common/composables/useEmitter';

const props = defineProps({
  // Denotes all the columns (available + selected)
  all_columns: {
    type: Array,
    required: true,
    default: () => [],
  },
  // Denotes the columns that are active (present on the right side)
  active_columns: {
    type: Array,
    required: true,
    default: () => [],
  },
  // An object that contains the following attributes: heading, left_heading, right_heading
  texts: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  // Used to v-bind to the HawkModalTemplate component
  options: {
    type: Object,
    default: () => ({}),
  },
  // Denotes if the available columns are nested or not
  is_nested: {
    type: Boolean,
    default: false,
  },
  // Denotes if the 'save' emit has to be emitted on every change or just when the save button is clicked
  immediate: {
    type: Boolean,
    default: true,
  },
  // Used to set the field name that is used to display the label of the field
  label_field_name: {
    type: String,
    default: 'label',
  },
  close_on_save: {
    type: Boolean,
    default: true,
  },
  is_loading_save: {
    type: Boolean,
    default: false,
  },
  // Denotes if multiple open groups are allowed in the outermost level (only applicable when is_nested is true)
  // Multiple open groups are allowed in the inner levels (by default)
  allow_multiple_open_groups: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close', 'save', 'forceChange']);

const draggable = defineAsyncComponent(() => import('vuedraggable'));

const emitter = useEmitter();

const $t = inject('$t');

const state = reactive({
  refresh_key: 0,
  __all_columns: [],
  available_columns: [],
  active_columns: props.active_columns,
  selected_active_columns: [],
  selected_available_columns: [],
  active_groups: {},
  shift_range_start_available_columns: -1,
  shift_range_end_available_columns: -1,
  shift_range_start_active_columns: -1,
  shift_range_end_active_columns: -1,
});

emitter.on('selection_add', (payload) => {
  selectAvailableColumns(payload.event, payload.column);
});

emitter.on('active_groups_changed', (payload) => {
  if (payload.type === 'add')
    if ((!props?.allow_multiple_open_groups && payload.level === 0) || !state.active_groups[payload.level])
      state.active_groups[payload.level] = [payload.value];
    else
      state.active_groups[payload.level].push(payload.value);
  else
    state.active_groups[payload.level] = state.active_groups[payload.level].filter(name => name !== payload.value);
});

onBeforeUnmount(() => {
  emitter.off('selection_add');
  emitter.off('active_groups_changed');
});

watch(() => props.active_columns, () => {
  state.active_columns = props.active_columns;
  for (const column of state.active_columns) {
    column._added = true;
    if (column?.name)
      addActiveProperty(column.name, state.__all_columns);
  }
  recalculateAvailableColumns();
});

watch(() => props.all_columns, (value) => {
  state.__all_columns = cloneDeep(value);
  for (const column of state.active_columns)
    if (column?.name)
      addActiveProperty(column?.name, state.__all_columns);
  recalculateAvailableColumns();
}, { immediate: true });

function addActiveProperty(name, columns) {
  for (const column of columns) {
    if (column.children)
      addActiveProperty(name, column.children);

    if (column.name === name)
      column._added = true;
    else if (!column.children && !column._added)
      column._added = false;
  }
}

function copyInactiveColumns(target) {
  let sanitized_target = cloneDeep(target);

  Object.keys(sanitized_target).forEach((key) => {
    if (typeof sanitized_target[key] === 'object' && sanitized_target[key] !== null && !sanitized_target[key]?._added)
      sanitized_target[key] = copyInactiveColumns(sanitized_target[key]);
    else if (sanitized_target[key]?._added)
      delete sanitized_target[key];
  });

  if (Array.isArray(sanitized_target))
    sanitized_target = Object.values(sanitized_target);

  return sanitized_target;
}

function recalculateAvailableColumns(event) {
  if (event?.added?.element?.name)
    toggleColumn(event.added.element.name, state.__all_columns, false);
  state.available_columns = copyInactiveColumns(state.__all_columns);
  state.refresh_key++;
}

function emitSave(is_save_clicked) {
  if (props.immediate)
    emit('save', {
      active_columns: state.active_columns,
      is_save_clicked,
    });
  else if (is_save_clicked)
    emit('save', state.active_columns);
}

function handleShiftSelection(name, total_list, selected_list, shift_range_start, shift_range_end, is_nested) {
  if (state[selected_list].length === 0) {
    state[shift_range_start] = name;
    state[shift_range_end] = name;
  }
  else {
    if (state[shift_range_start] === -1)
      state[shift_range_start] = state[selected_list][0].name;
    state[shift_range_end] = name;
  }

  if (is_nested) {
    const current_group_values = findColumn(name, state.available_columns, true);

    let start_index = current_group_values.findIndex(item => item.name === state[shift_range_start]);
    let end_index = current_group_values.findIndex(item => item.name === state[shift_range_end]);
    if (end_index < start_index)
      [start_index, end_index] = [end_index, start_index];

    state[selected_list] = current_group_values.slice(start_index, end_index + 1);
  }
  else {
    let start_index = total_list.findIndex(item => item.name === state[shift_range_start]);
    let end_index = total_list.findIndex(item => item.name === state[shift_range_end]);
    if (end_index < start_index)
      [start_index, end_index] = [end_index, start_index];
    state[selected_list] = total_list.slice(start_index, end_index + 1);
  }
}

function selectAvailableColumns(event, column) {
  if (event.shiftKey) {
    handleShiftSelection(column.name, state.available_columns, 'selected_available_columns', 'shift_range_start_available_columns', 'shift_range_end_available_columns', props.is_nested);
    return;
  }
  const does_include = state.selected_available_columns?.find?.(col => col.name === column.name);

  if (does_include) {
    if (state.selected_available_columns.length > 1)
      state.selected_available_columns = [column];

    else
      state.selected_available_columns = state.selected_available_columns.filter(
        n => n.name !== column.name,
      );

    return;
  }

  if (event.ctrlKey || event.metaKey)
    state.selected_available_columns.push(column);

  else
    state.selected_available_columns = [column];

  state.shift_range_start_available_columns = column.name;
}

function selectActiveColumns(event, column) {
  if (event.shiftKey) {
    handleShiftSelection(column.name, state.active_columns, 'selected_active_columns', 'shift_range_start_active_columns', 'shift_range_end_active_columns', false);
    return;
  }

  const does_include = state.selected_active_columns?.find(col => col.name === column.name);

  if (does_include) {
    if (state.selected_active_columns.length > 1)
      state.selected_active_columns = [column];

    else
      state.selected_active_columns = state.selected_active_columns.filter(
        n => n.name !== column.name,
      );

    return;
  }

  if (event.ctrlKey || event.metaKey)
    state.selected_active_columns.push(column);
  else
    state.selected_active_columns = [column];

  state.shift_range_start_active_columns = column.name;
}

function findColumn(name, columns, group = false) {
  for (const column of columns) {
    if (column.children) {
      const found = findColumn(name, column.children, group);
      if (found)
        return found;
    }

    if (column.name === name) {
      if (group)
        return columns;
      return column;
    }
  }

  return null;
}

function toggleColumn(name, columns, new_state) {
  for (const column of columns) {
    if (column.children)
      toggleColumn(name, column.children, new_state);

    if (column.name === name)
      column._added = new_state;
  }
}

function activateColumns(index) {
  let index_of_push = index ?? state.active_columns.length;
  for (const column of state.selected_available_columns) {
    if (column?._added)
      continue;
    toggleColumn(column.name, state.__all_columns, true);
    recalculateAvailableColumns();
    state.active_columns.splice(index_of_push, 0, { ...column });
    index_of_push++;
  }
  state.selected_active_columns = [];
  state.selected_available_columns = [];
  emitSave(false);
}

function deactivateColumns() {
  for (const column of state.selected_active_columns) {
    toggleColumn(column.name, state.__all_columns, false);
    recalculateAvailableColumns();
    state.active_columns = state.active_columns.filter(
      col => col.name !== column.name,
    );
  }
  state.selected_active_columns = [];
  state.selected_available_columns = [];
  emitSave(false);
}

function changeIndex(val) {
  const arr = state.active_columns;
  for (const column of state.selected_active_columns) {
    const from = arr.findIndex(col => col.name === column.name);
    const to = from + val;
    if (to < 0 || to > state.selected_active_columns)
      continue;
    const element = arr[from];
    arr.splice(from, 1);
    arr.splice(to, 0, element);
  }
  emitSave(false);
}

function saveColumns() {
  emitSave(true);
  if (props.close_on_save)
    emit('close');
}

function getLeafElements(current_element) {
  const leaf_elements = [];
  if (current_element?.children?.length)
    for (const child of current_element.children)
      if (child.children)
        leaf_elements.push(...getLeafElements(child));
      else
        leaf_elements.push(child);
  else leaf_elements.push(current_element);

  return leaf_elements;
}

function onChange(event) {
  if (event.added) {
    state.active_columns = uniqBy(state.active_columns, 'name');
    if (event.added.element?.children?.length) {
      const leaf_elements = getLeafElements(event.added.element);
      state.active_columns = state.active_columns.filter(col => !col?.children?.length);
      state.selected_available_columns = leaf_elements;
      activateColumns(event.added.newIndex);
      state.active_columns = uniqBy(state.active_columns, 'name');
    }
    else if (state.selected_available_columns.length > 1) {
      activateColumns(event.added.newIndex);
      state.active_columns = uniqBy(state.active_columns, 'name');
    }
    else {
      toggleColumn(event.added.element.name, state.__all_columns, true);
      recalculateAvailableColumns();
    }
  }
  else if (event.removed) {
    if (state.selected_active_columns.length > 1) {
      deactivateColumns();
    }
    else {
      toggleColumn(event.removed.element.name, state.__all_columns, false);
      recalculateAvailableColumns();
    }
  }

  emitSave(false);
}

onKeyStroke('ArrowRight', () => {
  if (state.selected_available_columns.length)
    activateColumns();
});

onKeyStroke('ArrowLeft', () => {
  if (state.selected_active_columns.length)
    deactivateColumns();
});

onKeyStroke('ArrowDown', () => {
  changeIndex(+1);
});

onKeyStroke('ArrowUp', () => {
  changeIndex(-1);
});
</script>

<template>
  <HawkModalTemplate v-bind="options" @close="$emit('close')">
    <template #title_text>
      <div class="text-lg font-semibold text-gray-900">
        {{ props.texts.heading }}
      </div>
    </template>
    <div :key="state.available_columns" class="w-[59.5rem]">
      <div class="flex items-center gap-6">
        <div class="w-full">
          <div class="mb-2 text-sm font-semibold text-gray-900">
            {{ props.texts.left_heading }}
          </div>
          <!-- Display the nested available columns -->
          <div v-if="props.is_nested" class="border border-gray-300 rounded-lg py-3 px-4 scrollbar h-[45vh]">
            <HawkDraggableTree
              :key="state.refresh_key"
              class="h-full"
              :available_columns="state.available_columns"
              :label_field_name="props.label_field_name"
              :selected_available_columns="state.selected_available_columns"
              :active_groups="state.active_groups"
              :level="0"
              @reject="recalculateAvailableColumns($event)"
            />
          </div>
          <!-- Display the available columns in a flat structure -->
          <draggable
            v-else
            :list="state.available_columns"
            :group="{ name: 'columns' }"
            item-key="name"
            class="border border-gray-300 rounded-lg py-3 px-4 scrollbar h-[45vh]"
          >
            <template #item="{ element: column }">
              <div
                v-if="!column._added"
                class="flex items-center h-8 cursor-pointer hover:bg-gray-50"
                :class="{ 'bg-gray-100 hover:!bg-gray-100': state.selected_available_columns.find(col => col.name === column.name) }"
                @click="selectAvailableColumns($event, column)"
              >
                <span class="ml-2 text-sm font-medium text-gray-700">
                  {{ column?.[label_field_name] }}
                </span>
              </div>
            </template>
          </draggable>
        </div>
        <div class="w-8">
          <div
            class="flex items-center justify-center w-8 h-8 mb-6 bg-gray-100 rounded-full cursor-not-allowed"
            :class="{ 'cursor-pointer': state.selected_available_columns.length }"
            @click="activateColumns(state.active_columns.length)"
          >
            <IconHawkArrowRight class="text-gray-600" />
          </div>
          <div
            class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full cursor-not-allowed"
            :class="{ 'cursor-pointer': state.selected_active_columns.length }"
            @click="deactivateColumns"
          >
            <IconHawkArrowLeft class="text-gray-600" />
          </div>
        </div>
        <div class="w-full">
          <div class="mb-2 text-sm font-semibold text-gray-900">
            {{ props.texts.right_heading }}
          </div>
          <div class="border border-gray-300 rounded-lg py-3 px-4 scrollbar h-[45vh]">
            <draggable
              :list="state.active_columns"
              :group="{ name: 'columns' }"
              item-key="name"
              class="h-full"
              @change="onChange"
            >
              <template #item="{ element: column }">
                <div
                  class="flex items-center h-8 cursor-pointer hover:bg-gray-50"
                  :class="{ 'bg-gray-100 hover:!bg-gray-100': state.selected_active_columns.find(col => col.name === column.name) }"
                  @click="selectActiveColumns($event, column)"
                >
                  <span class="ml-2 text-sm font-medium text-gray-700">
                    {{ column?.[label_field_name] }}
                  </span>
                </div>
              </template>
            </draggable>
          </div>
        </div>
        <div class="w-8">
          <div
            class="flex items-center justify-center w-8 h-8 mb-6 bg-gray-100 rounded-full cursor-pointer"
            @click="changeIndex(-1)"
          >
            <IconHawkArrowUp class="text-gray-600" />
          </div>
          <div
            class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full cursor-pointer"
            @click="changeIndex(+1)"
          >
            <IconHawkArrowDown class="text-gray-600" />
          </div>
        </div>
      </div>
    </div>
    <slot name="additional_content" />
    <template #footer>
      <slot name="footer">
        <Vueform size="sm">
          <div class="flex justify-end w-full col-span-full">
            <ButtonElement
              name="cancel"
              class="mr-4"
              :secondary="true"
              @click="emit('close')"
            >
              {{ $t('Cancel') }}
            </ButtonElement>
            <ButtonElement
              name="save"
              :loading="is_loading_save"
              @click="saveColumns"
            >
              {{ $t('Save') }}
            </ButtonElement>
          </div>
        </Vueform>
      </slot>
    </template>
  </HawkModalTemplate>
</template>
